<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="processRequestModal"
      centered
      title="Tạo yêu cầu cho văn bằng, chứng chỉ"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
      @show="onShow"
      @hide="onHide"
    >
      <div class="row">
        <div class="col-xl-3 col-lg-5 col-md-5 order-1 order-md-0">
          <div class="card">
            <div class="card-body">
              <b-row>
                <b-col>
                  <h3>
                    Thông tin yêu cầu của sinh viên:
                  </h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="info-container">
                    <ul class="list-unstyled">
                      <li class="mb-1 mt-2">
                        <span class="fw-medium me-1">
                          Tên văn bằng, chứng chỉ:
                        </span>
                        <strong>
                          {{ infoRequest.credentialName }}
                        </strong>
                      </li>
                      <li class="mb-1 pt-1">
                        <span class="fw-medium me-1">
                          Tên sinh viên:
                        </span>
                        <strong>
                          {{ infoRequest.studentName }}
                        </strong>
                      </li>
                      <li class="mb-1 pt-1">
                        <span class="fw-medium me-1">
                          Nội dung yêu cầu:
                        </span>
                        <strong>
                          {{ infoRequest.name }}
                        </strong>
                      </li>
                      <li class="mb-1 pt-1">
                        <span class="fw-medium me-1">
                          Loại yêu cầu:
                        </span>
                        <strong>
                          {{ infoRequest.certificateProcessTypeName }}
                        </strong>
                      </li>
                      <li
                        v-for="(item, index) in fileSubmited"
                        :key="index"
                        ref="row"
                        class="mb-1 pt-1"
                      >
                        <span class="fw-medium me-1">
                          File yêu cầu của sinh viên ứng với "{{ filesRequired[index].name}}":
                        </span>
                        <strong>
                          <a :href="item.filePath">{{ item.fileName }} <feather-icon icon="DownloadIcon"/></a>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="item.status === 0"
                  class="text-left"
                >
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="my-2"
                      variant="success"
                      @click="acceptProcessRequest"
                    >
                      <feather-icon icon="CheckIcon" />
                      Chấp nhận
                    </b-button>
                  </b-button-group>
                </b-col>
                <b-col
                    v-if="item.status !== 2"
                    class="text-right"
                >
                  <b-button-group>
                    <b-button
                      v-b-modal.messageDeny
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="my-2"
                      variant="danger"
                      @click="denyProcessRequest"
                    >
                      <feather-icon icon="XCircleIcon" />
                      Từ chối
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>

            </div>

          </div>
        </div>
        <div class="col-xl-9 col-lg-7 col-md-7 order-0 order-md-1">
          <div class="card">
            <div class="card-body">
              <h3>Văn bằng, chứng chỉ </h3>
              <div class=" mb-3">
                <div class="dataTables_wrapper dt-bootstrap5 no-footer">
                  <b-row>
                    <b-col>
                      <b-button-group>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.credentialStorageSaveModal
                          class="my-2"
                          variant="success"
                          @click="addCredentialForStudent"
                        >
                          <feather-icon icon="PlusIcon" />
                          Bổ sung
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12">
                      <vue-good-table
                        mode="remote"
                        class="my-table"
                        row-style-class="vgt-row"
                        style-class="vgt-table striped bordered"
                        :columns="columns"
                        :rows="credentialStorage"
                        :pagination-options="paginationOptions"
                        :line-numbers="true"
                      >
                        <div
                          slot="emptystate"
                          style="text-align: center; font-weight: bold"
                        >
                          Không có bản ghi nào !
                        </div>
                        <template
                          slot="table-row"
                          slot-scope="props"
                        >
                          <span v-if="props.column.field === 'status'">
                            {{ getStatusName(props.row.status) }}
                          </span>
                          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                          <span
                            v-if="props.column.field === 'action'"
                            class="thead-group-action"
                          >
                            <b-button-group>
                              <b-button
                                v-b-modal.credentialStorageSaveModal
                                class="btn-icon"
                                variant="primary"
                                @click="onUpdateCredentialInStorage(props.row)"
                              >
                                <feather-icon icon="Edit2Icon" />
                              </b-button>
                            </b-button-group>
                          </span>
                        </template>
                      </vue-good-table>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <CredentialStorageSave
      ref="credentialStorageSaveModal"
      :item="currentStudentCredentialStorage"
      :credential-process-req="item"
      @succeed="onSucceed"
    />
    <MessageDeny
        ref="messageDeny"
        :credential-process-request-id="credentialProcessRequestId"
        :credential-process-request-message="credentialProcessRequestMessage"
    />
  </validation-observer>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard, BCardBody, BCardHeader, BCardTitle,
  BCol,
  BContainer,
  BDropdownItem, BFormFile,
  BFormGroup, BFormInput,
  BFormSelect, BModal,
  BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CredentialStorageSave from '@/views/credential-storage/CredentialStorageSave.vue'
import MessageDeny from '@/views/credential-process-request/MessageDeny.vue'

export default {
  name: 'ProcessRequestModal',
  directives: {
    Ripple,
  },
  components: {
    MessageDeny,
    CredentialStorageSave,
    BModal,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BRow,
    BFormInput,
    BFormFile,
    Ripple,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isAllowRegister: true,
      isLoading: false,
      user: getUser(),
      filter: {
        type: null,
        name: null,
      },
      filterAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      currentCredential: undefined,
      currentStudentCredentialStorage: undefined,
      paginationOptions: {
        enabled: false,
      },
      currentCredentialCondition: null,
      infoRequest: {
        credentialName: '',
        name: '',
        studentName: '',
        certificateProcessTypeName: '',
        filesName: [],

      },
      targetItem: {
        credentialId: '',
        name: '',
        birthday: '',
        address: '',
        genderCode: '',
        ethnicCode: '',
        nationality: '',
        graduationText: '',
        yearId: '',
        statusPrint: '',
        majorId: '',
        trainingSystemId: '',
        classId: '',
        status: '',
      },
      filesRequired: [],
      fileSubmited: [],
      credentialProcessRequestId: null,
      credentialProcessRequestMessage: '',
      columns: [
        {
          label: 'Tên sinh viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Quốc tịch',
          field: 'nationality',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngành đào tạo',
          field: 'major',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên văn bằng, chứng chỉ',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Xếp loại',
          field: 'graduationText',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu văn bằng',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      credentialProcessTypes: 'processRequest/credentialProcessTypes',
      credentialFilesRequire: 'processRequest/credentialFilesRequire',
      credentialStorage: 'processRequest/credentialStorage',
      statuses: 'processRequest/statuses',
    }),
  },
  async created() {
    this.infoRequest = this.item || this.infoRequest
    this.credentialProcessRequestId = this.item.id
  },
  methods: {
    ...mapActions({
      readCredentialProcessTypes: 'processRequest/readCredentialProcessTypes',
      readCredentialFileRequire: 'processRequest/readCredentialFileRequire',
      uploadSample: 'processRequest/uploadSample',
      getPathFileSample: 'processRequest/getPathFileSample',
      createCredentialProcessRequest: 'processRequest/createCredentialProcessRequest',
      readStudentsCredentialStorage: 'processRequest/readStudentsCredentialStorage',
      updateStatusProcess: 'processRequest/updateStatusProcess',
    }),
    async onSelectCredentialCondition(row) {
      if (this.currentCredentialCondition === row.row) {
        this.currentCredentialCondition = null
        this.filesRequired = []
      } else {
        this.filesRequired = []
        this.currentCredentialCondition = row.row
        this.targetItem.certificateProcessTypeId = this.currentCredentialCondition.idCertificateProcessTypeFk
        this.targetItem.studentId = this.user.studentId
        this.targetItem.credentialId = this.item.credentialId
        this.targetItem.credentialStorageId = this.item.id
        await this.readCredentialFileRequire({ id: this.currentCredentialCondition.id })
        for (const file of this.credentialFilesRequire) {
          const { fileName } = file
          const filePath = await this.getPathFileSample({
            pathFile: fileName,
            contentType: 'application/octet-stream',
          })
          this.filesRequired.push({ filePath, fileName })
        }
      }
    },
    async onShow() {
      this.infoRequest = this.item || this.infoRequest
      await this.readCredentialProcessTypes({
        id: this.item.credentialId,
        params: this.filterAll,
      })
      if (this.item.studentId !== null) {
        await this.readStudentsCredentialStorage({
          currentPage: 1,
          itemsPerPage: 10,
          studentId: this.item.studentId,
        })
      }
      this.credentialProcessRequestId = this.item.id
      this.credentialProcessRequestMessage = this.item.description
      await this.readCredentialFileRequire({ id: this.infoRequest.credentialProcessTypeId })
      for (const file of this.credentialFilesRequire) {
        const { name } = file
        this.filesRequired.push({ name })
      }
      for (const fileName of this.infoRequest.filesName) {
        const filePath = await this.getPathFileSample({
          pathFile: fileName,
          contentType: 'application/octet-stream',
        })
        this.fileSubmited.push({ filePath, fileName })
      }
    },
    onHide() {
      this.targetItem = {
        studentId: '',
        credentialId: '',
        credentialStorageId: '',
        certificateProcessTypeId: '',
        name: '',
        filesId: [],
        status: 0,
      }
      this.filesRequired = []
      this.fileSubmited = []
      this.credentialProcessRequestId = null
      this.$emit('succeed')
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    async uploadFile(e) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      files.forEach(file => formData.append('files', file))

      this.mediaIds = await this.uploadSample(formData)

      if (this.mediaIds.length > 0) {
        this.targetItem.filesId.push(this.mediaIds[0])
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.isLoading = false
    },
    downloadFile(fileUrl, fileName) {
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.createCredentialProcessRequest(this.targetItem)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('createCredentialConditionModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async addCredentialForStudent() {
      this.currentStudentCredentialStorage = undefined
      // this.currentStudentCredentialStorage = { ...this.currentStudentCredentialStorage, credentialProcessRequestId: this.item.id }
    },
    async onUpdateCredentialInStorage(row) {
      this.currentStudentCredentialStorage = row
      // this.currentStudentCredentialStorage = { ...this.currentStudentCredentialStorage, credentialProcessRequestId: this.item.id }
    },
    async onSucceed() {
      await this.readStudentsCredentialStorage({
        currentPage: 1,
        itemsPerPage: 10,
        studentId: this.item.studentId,
      })
    },
    async acceptProcessRequest() {
      this.isLoading = true
      try {
        const response = await this.updateStatusProcess({
          id: this.item.id,
          params: { status: 1 },
        })
        if (response) {
          const { isSuccessfulReq, messageReq } = response
          if (isSuccessfulReq) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: messageReq,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: messageReq,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async denyProcessRequest() {
      this.isLoading = true
      try {
        const response = await this.updateStatusProcess({
          id: this.item.id,
          params: { status: -1 },
        })
        if (response) {
          const { isSuccessfulReq, messageReq } = response
          if (isSuccessfulReq) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: messageReq,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.credentialProcessRequestId = this.item.id
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: messageReq,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    // async completeProcessRequest() {
    //   this.isLoading = true
    //   try {
    //     const response = await this.updateStatusProcess({
    //       id: this.item.id,
    //       params: { status: 2 },
    //     })
    //     if (response) {
    //       const { isSuccessful, message } = response
    //       if (isSuccessful) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: message,
    //             icon: 'CheckIcon',
    //             variant: 'success',
    //           },
    //         })
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: message,
    //             icon: 'XCircleIcon',
    //             variant: 'danger',
    //           },
    //         })
    //       }
    //     }
    //   } catch (e) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: `[${e.code}] ${e.message}`,
    //         icon: 'XCircleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   } finally {
    //     this.isLoading = false
    //   }
    // },

  },
}
</script>

<style scoped lang="scss">
</style>
